const FAQ = () => {
  return (
    <div>
      <div className="grid gap-4 place-items-left">
        <div className="row-span-2 p-4">
          <h3 className="text-3xl">Frequently Asked Questions</h3>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Will it fit indoors?</p>
          <p className="p-1">A: It depends. Please look at the size of specific bouncer then add an additional 6 feet needed for the inlet and blower.</p>
          <p className="p-1">But we are creative and have already set it up diagonally, even setting up the blower inside an attached bathroom.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Can I make my own garland and attach it to the castle?</p>
          <p className="p-1">A: Yes! They are easy to attach.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Will the bouncer be clean?</p>
          <p className="p-1">A: Absolutely! We clean and sanitize after every rental.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Can I use the castle for a wedding?</p>
          <p className="p-1">A: Of course! Any occasion 🤍</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: What are the dimensions of the castle?</p>
          <p className="p-1">A: We have something for everyone from 8x10 feet to 13x13 feet.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Does the castle include delivery & setting up?</p>
          <p className="p-1">A: We offer free delivery in the Forsyth county and surrounding areas. For other areas it is an additional fee.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: What surface do you set up on?</p>
          <p className="p-1">A: We can setup on grass, turf, concrete, pavers & asphalt. We cannot set up on rocks as it will wear through our vinyl structure.</p>
        </div>
        <div className="p-4">
          <p className="p-1">Q: Is there a weather policy?</p>
          <p className="p-1">A: If it is raining the day of your event, you are able to reschedule for any future date available. If you decide to cancel, we will hold $75 towards your next rental. The inflatable must be turned off if winds exceed 25 mph. No exceptions.</p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
