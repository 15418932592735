import { useParams } from "react-router-dom";
import data from './data/data.json';


const RentUnit = () => {
    const params = useParams();
    const rentalUnit = data.find(d => d.id == params.id);
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return (
        <>
            <div className="grid gap-4 place-items-center">
                <div>
                    <img className="lg:w-1/3 m-auto p-4 space-y-4" src={`../assets/img/${rentalUnit.path}.jpg`}></img>
                </div>
                <div className="row-span-2">
                    <h3 className="text-3xl">{rentalUnit.name}</h3>
                </div>
                <div className="p-4">
                    <p className="text-lg font-serif">{rentalUnit.description}</p>
                </div>
                <div className="p-4">
                    <p className="text-lg font-serif">{rentalUnit.additional_description}</p>
                </div>
                <div className="p-4">
                    <p className="text-lg font-serif">Ages: {rentalUnit.ages}</p>
                </div>
                <div>
                          {/* Calendly inline widget begin */}
                        <div className="calendly-inline-widget" data-url="https://calendly.com/joybouncebounce/rental" style={{minWidth: '320px', height: '630px'}} />
                        {/* Calendly inline widget end */}
                </div>
            </div>
        </>
    );
}

export default RentUnit;