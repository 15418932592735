import { Carousel, Label, TextInput, Checkbox, Button } from "flowbite-react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Balloons = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { name, email, message } = data;
    try {
      const subject = `Balloon Quote Request for ${name}`;
      const templateParams = {
        name,
        email,
        subject,
        message,
      };
      await emailjs.send(
        "service_4eo0jom",
        "template_kyrpn2a",
        templateParams,
        "vAqW5vuk7qwXlzSmT"
      );
      reset();
      toastifyMessage(
        toast.TYPE.SUCCESS,
        "Booking invite sent, we will get back to you within 72 hours"
      );
    } catch (e) {
      console.error(e);
      toastifyMessage(
        toast.TYPE.ERROR,
        "Oops an error occurred. Please try again later"
      );
    }
  };

  const toastifyMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "bottom-right",
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyBalloonRequestToast",
    });
  };

  return (
    <>
      <div className="h-96 sm:h-64 md:h-screen">
        <Carousel slideInterval={4000}>
          <img
            src="assets/img/ballons_to_the_roof.jpg"
            alt="Ballons up to the roof!"
          />
          <img src="assets/img/wall_balloons.jpg" alt="Wall Balloons" />
          <img
            src="assets/img/toddler_bouncers_with_balloons.jpg"
            alt="Todler Bouncers with Beautiful Balloon Garlands"
          />
          <img
            src="assets/img/cute_house_with_ballons.jpg"
            alt="Cute House with Balloon Garlands"
          />
          <img
            src="assets/img/baby_shower_ballons.jpg"
            alt="Baby Shower Arch"
          />
          <img
            src="assets/img/multi_wall_balloons.jpg"
            alt="Multiple Walls with Balloon Garlands"
          />
          <img src="assets/img/balloons_mickey.jpg" alt="Mickey Balloons" />
        </Carousel>
      </div>
      <div className="grid gap-4 place-items-center">Book now</div>
      <form
        className="flex flex-col gap-4 p-4"
        id="ballon-book"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Your email" />
          </div>
          <TextInput
            id="email"
            type="email"
            required={true}
            {...register("email", {
              required: {
                value: true,
                message: "Please enter your email",
              },
              pattern:
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            })}
          />
          {errors.email && (
            <span className="errorMessage">{errors.email.message}</span>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name" value="Name" />
          </div>
          <TextInput
            id="name"
            type="text"
            sizing="sm"
            required={true}
            {...register("name", {
              required: {
                value: true,
                message: "Please enter your name",
              },
              maxLength: {
                value: 30,
                message: "Please use 30 characters or less",
              },
            })}
          />
          {errors.name && (
            <span className="errorMessage">{errors.name.message}</span>
          )}
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="message" value="Tell us more about your event" />
          </div>
          <TextInput
            id="message"
            type="text"
            sizing="lg"
            required={true}
            {...register("message", {
              required: {
                value: true,
                message: "Please enter your message",
              },
            })}
          />
          {errors.message && (
            <span className="errorMessage">{errors.message.message}</span>
          )}
        </div>
        <Button className="bg-red-500 hover:bg-red-500" type="submit">
          Request Quote
        </Button>
      </form>
    </>
  );
};

export default Balloons;
