const About = () => {
    return (
        <div>
          <div className="grid gap-4 place-items-center">
              <div className="p-4">
                  <p>We are a business family from Brazil that loves parties.</p>
              </div>
              <div className="p-4">
                <p>Being part of your memorable date is an honor for us.</p>
              </div>
              <div className="p-4">
                <p>Our mission is to bring JOY to your party, bringing family and friends together.</p>
              </div>
              <div className="p-4">
                <p>Celebrating life and success is important and we will love to be part of your event.</p>
              </div>
              <div className="p-4">
                <p>We are here to serve you!</p>
              </div>
          </div>
        </div>
      );
}

export default About;