const Rules = () => {
    return (
      <div>
        <div className="grid gap-4 place-items-left">
            <div className="row-span-2 p-4">
                <h3 className="text-3xl">Bouncer Rules</h3>
            </div>
            <div className="p-4">
              <p className="p-1">What are the rules for the bouncer?</p>
              <ul className="list-disc pl-5">
                  <li>NO SHOES</li>
                  <li>NO FOOD/DRINKS</li>
                  <li>NO WATER</li>
                  <li>NO CLIMBING</li>
                  <li>NO ANIMALS</li>
                  <li>NO FACE PAINT/SILLY STRING/MARKERS</li>
                  <li>NO SHARP OBJECTS</li>
                  <li>NO BALLS OUT OF THE BALL PIT</li>
              </ul>
            </div>
        </div>
      </div>
    );
  }

  export default Rules;