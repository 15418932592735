import { Link } from "react-router-dom";
import data from './data/data.json';

const BouncerList = () => (
    <div className="grid place-items-center min-h-screen">
        <div>
            <h2 className="text-4xl sm:text-2xl md:text-4xl lg:text-4xl font-serif">Luxurious Bouncers</h2>
        </div>
        <div> 
        </div>
        {data.map(item => (
          <div key={item.id} className="lg:w-1/3 p-4 space-y-4">
            <img src={`assets/img/${item.path}.jpg`}></img>
            <div className="p-6">
                <Link className="p-4 border-4 text-center border-black bg-white text-black font-semibold hover:bg-black hover:text-white rounded" to={`rent/${item.id}`}>Rent {item.name}</Link>
            </div>
          </div>
        ))}
        
    </div>
);

export default BouncerList;