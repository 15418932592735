import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/BouncerList";
import RentUnit from "./components/RentUnit";
import Balloons from "./components/Balloons";
import ReactGA from "react-ga4";
import Rules from "./components/Rules";
import About from "./components/About";
import FAQ from "./components/FAQ";
import NoMatch from "./components/NoMatch";
import Layout from "./components/Layout";
import Contact from "./components/Contact";

export default function App() {
  setGA();
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="rent/:id" element={<RentUnit />} />
          <Route path="balloons" element={<Balloons />} />
          <Route path="about" element={<About />} />
          <Route path="rules" element={<Rules />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contact" element={<Contact />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function setGA() {
  ReactGA.initialize("G-9JWGDWBNWY");
  ReactGA.send("pageview");
}
