import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import logo from "./../logo.jpg";
import FooterFn from "./Footer";

const Layout = () => {
  function toggleMobileMenu(e) {
    e.preventDefault();
    const menu = document.getElementById("mobile-menu");
    menu.classList.toggle("hidden");
  }

  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
                share across all the pages on your site, like navigation. */}
      <nav className="nav">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                id="mobile-menu-button"
                onClick={toggleMobileMenu}
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {/* <!--
            Icon when menu is closed.

            Heroicon name: outline/bars-3

            Menu open: "hidden", Menu closed: "block"
            --> */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                {/* <!--
            Icon when menu is open.

            Heroicon name: outline/x-mark

            Menu open: "block", Menu closed: "hidden"
            --> */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="block h-8 w-auto lg:hidden"
                  src={logo}
                  alt="Joy Bounce Balloons"
                ></img>
                <img
                  className="hidden h-8 w-auto lg:block"
                  src={logo}
                  alt="Joy Bounce Balloons"
                ></img>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    aria-current="page"
                    to={`/`}
                  >
                    Bouncers
                  </Link>

                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    to={`/balloons`}
                  >
                    Balloons
                  </Link>

                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    to={`/about`}
                  >
                    About
                  </Link>

                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    to={`/rules`}
                  >
                    Rules
                  </Link>

                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    to={`/faq`}
                  >
                    FAQs
                  </Link>

                  <Link
                    className="bg-rose-300 text-white px-3 py-2 rounded-md text-sm font-medium"
                    to={`/contact`}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            </div> --> */}
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div className="hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pt-2 pb-3">
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
              to={`/`}
            >
              Bouncers
            </Link>

            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              to={`/balloons`}
            >
              Balloons
            </Link>

            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              to={`/about`}
            >
              About
            </Link>

            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              to={`/rules`}
            >
              Rules
            </Link>

            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              to={`/faq`}
            >
              FAQs
            </Link>

            <Link
              className="text-gray-500 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
              to={`/contact`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>

      <hr />

      {/* An <Outlet> renders whatever child route is currently active,
                so you can think about this <Outlet> as a placeholder for
                the child routes we defined above. */}
      <Outlet />

      <FooterFn></FooterFn>
    </div>
  );
};

export default Layout;
