import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { name, email, message } = data;
    try {
      const subject = `Contact Us Request for ${name}`;
      const templateParams = {
        name,
        email,
        subject,
        message,
      };
      await emailjs.send(
        "service_4eo0jom",
        "template_kyrpn2a",
        templateParams,
        "vAqW5vuk7qwXlzSmT"
      );
      reset();
      toastifyMessage(
        toast.TYPE.SUCCESS,
        "Contact us request sent, we will get back to you within 72 hours"
      );
    } catch (e) {
      console.error(e);
      toastifyMessage(
        toast.TYPE.ERROR,
        "Oops an error occurred. Please try again later"
      );
    }
  };

  const toastifyMessage = (type, message) => {
    toast(message, {
      type: type,
      position: "bottom-right",
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyContactUsToast",
    });
  };

  return (
    <div className="ContactForm">
      <div className="container mx-auto sm:px-4">
        <div className="flex flex-wrap ">
          <div className="w-full text-center">
            <div className="contactForm">
              <form
                id="contact-form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* Row 1 of form */}
                <div className="flex flex-wrap formRow">
                  <div className="relative flex-grow max-w-full flex-1 px-4">
                    <input
                      type="text"
                      name="name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                        maxLength: {
                          value: 30,
                          message: "Please use 30 characters or less",
                        },
                      })}
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded formInput"
                      placeholder="Name"
                    ></input>
                    {errors.name && (
                      <span className="errorMessage">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap formRow">
                  <div className="relative flex-grow max-w-full flex-1 px-4">
                    <input
                      type="email"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded formInput"
                      placeholder="Email address"
                    ></input>
                    {errors.email && (
                      <span className="errorMessage">
                        Please enter a valid email address
                      </span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className="flex flex-wrap  formRow">
                  <div className="relative flex-grow max-w-full flex-1 px-4">
                    <textarea
                      rows={3}
                      name="message"
                      {...register("message", {
                        required: true,
                      })}
                      className="block appearance-none w-full py-1 px-2 mb-1 text-base leading-normal bg-white text-gray-800 border border-gray-200 rounded formInput"
                      placeholder="Message"
                    ></textarea>
                    {errors.message && (
                      <span className="errorMessage">
                        Please enter a message
                      </span>
                    )}
                  </div>
                </div>
                <button className="submit-btn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
