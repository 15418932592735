import React from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { Footer } from "flowbite-react";

const FooterFn = () => {
  return (
    <Footer container={true}>
      <div className="w-full sticky top-[100vh]">
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          {/* <Footer.Brand
            href="#"
            src="../logo.png"
            alt="Joy Bounce Balloons"
            name="Joy Bounce Balloons"
          /> */}
          <Footer.Copyright href="#" by="Joy Bounce Balloons" year={new Date().getFullYear()} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="https://www.facebook.com/profile.php?id=100078708682282&mibextid=LQQJ4d" icon={BsFacebook} />
            <Footer.Icon href="https://www.instagram.com/joybounceballoons" icon={BsInstagram} />
          </div>
        </div>
      </div>
    </Footer>
  );
};
export default FooterFn;
